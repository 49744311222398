@font-face {
    font-family: "Neue";
    font-style: normal;
    font-weight: 400;
    src: url(../static/font/Neue-Regular.woff) format("woff")
}

@font-face {
    font-family: "Neue";
    font-style: bold;
    font-weight: 700;
    src: url(../static/font/Neue-Bold.woff) format("woff")
}

@font-face {
    font-family: "Neue";
    font-style: light;
    font-weight: 300;
    src: url(../static/font/Neue-Book.woff) format("woff")
}


* {
    margin: 0;
    padding: 0;
}

:root {
    --max-width: 2400px;
}

html,
body {
    overflow: hidden;
    font-family: 'Neue', sans;
    color: white;
    font-size: 18px;
}

.bg, .text, #wrapper {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
}

#wrapper {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

nav, footer {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: loadPage 1s ease-out 2.7s forwards;
}

.nav, .footer, main {
    width: 100%;
    max-width: var(--max-width);
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

main {
    justify-content: left;
    height: 100%;
}

.main {
    padding: 0px 24px;
}

main h1 {
    text-transform: uppercase;
    font-size: 15vh;
    line-height: 0.9em;
    font-weight: bold;
    padding: 0;
    margin: 0;
    transform: rotateX(90deg) rotateY(10deg);
    transform-style: preserve-3d;
    transform-origin: 50% 50% 0px;
}

.h1-wrap1 h1 {
    font-weight: normal;
    animation: loadH1 1s ease-out 2s forwards;
}

.h1-wrap2 h1 {
    animation: loadH1 1s ease-out 2.2s forwards;
}

.h1-wrap3 h1 {
    animation: loadH1 1s ease-out 2.4s forwards;
}

@keyframes loadH1 {
    from {
        opacity: 0;
        transform: rotateX(90deg) rotateY(3deg);
    }

    to {
        opacity: 1;
        transform: rotateX(0deg) rotateY(0deg);
    }
}

.intro {
    position: relative;
    margin-bottom: 86px;
    padding-top: 0.3em;
    padding-bottom: 20px;
    transform: perspective(600px);
}

ul {
    text-align: center;
    padding-left: 0;
    display: flex;
    align-items: center;
}

li {
    display: inline-block;
    padding: 0px 12px;
    transition: opacity 0.6s;
}

li img {
    height: 1.2em;
    margin-top: 5px;
}

li:hover {
    opacity: 0.8;
}

footer {
    height: 100px;
    position: absolute;
    bottom: 0;
}

footer span {
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.logo {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2em;
    font-weight: bold;
    cursor: default;
}

.contact a {
    background: none;
    text-transform: uppercase;
    outline: none;
    border: none;
    color: white;
    font-family: inherit;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-decoration: none;
}


@keyframes loadPage {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 680px) {
    main h1 {
        font-size: 20vw;
    }
}